/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .sidebar {
  background-color: #ececec;
}
.altai-theme-admin .rightbar {
  padding: 0;
  background-color: #fafafa;
}
.altai-theme-admin .task-comment-create form {
  padding: 0;
  color: #333;
}
.altai-theme-admin .fullwidthcard {
  margin: 10px 0 10px 0;
  padding: 10px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3);
  background-color: white;
  display: flex;
  flex-direction: column;
}
.altai-theme-admin .fullwidthcard .task-details {
  font-size: 12px;
  display: flex;
}
.altai-theme-admin .fullwidthcard .task-details .detailicon {
  margin-right: 10px;
}
.altai-theme-admin .fullwidthcard .task-details .task-detail-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
}
.altai-theme-admin .fullwidthcard .task-details .task-detail-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: auto;
  flex: 1;
}
.altai-theme-admin .fullwidthcard .task-details .task-detail {
  margin-left: 10px;
  padding: 10px 5px 0px 5px;
}
.altai-theme-admin .fullwidthcard .task-details .detail-taskname {
  font-weight: 600;
}
.altai-theme-admin .task-additions {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 15px 0;
}
.altai-theme-admin .commentlist {
  margin: 10px 0 10px 0;
}
.altai-theme-admin .commentlist .commentcard:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #a5a5a5;
  display: block;
  width: 0;
  z-index: 1;
  margin-left: -30px;
  margin-top: -10px;
  margin-right: 15px;
}
.altai-theme-admin .commentlist .commentcard {
  /* border-radius: 5px; */
  padding: 15px;
  background-color: #a5a5a5;
  border-top: 4px solid #cacaca;
  color: white;
  margin: 0 0 15px 15px;
  font-size: 10px;
  border-radius: 3px;
}
.altai-theme-admin .commentlist .commentcard .content-relation-file-list table {
  margin: 20px 0 0px 0;
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 4px solid #eee;
  width: 100%;
  background-color: transparent;
}
.altai-theme-admin .commentlist .commentcard .comment-additions {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-content: flex-end;
  margin: 30px 0 0 0;
}
.altai-theme-admin .commentlist .commentcard .title {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 800;
}
.altai-theme-admin .commentlist .commentcard .created {
  margin-bottom: 10px;
}
.altai-theme-admin .commentlist .commentcard .message {
  font-style: italic;
  font-size: 12px;
}
.altai-theme-admin .task-comment-create {
  margin-top: 20px;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
}
.altai-theme-admin .task-comment-create .content-relation-file-list table {
  margin: 20px 0 0px 0;
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 4px solid #eee;
  width: 100%;
  background-color: transparent;
}
.altai-theme-admin .task-comment-create .altai-form .form-group .altai-content-attribute-update-item {
  margin-top: 5px;
  margin-left: 0px;
}
.altai-theme-admin .task-comment-create .altai-content-relation-update {
  flex-direction: column;
}
.altai-theme-admin .task-comment-create .altai-content-relation-update .header {
  flex: 0 0 25px;
  margin-bottom: 10px;
}
.altai-theme-admin .task-comment-create .altai-file-dropzone .upload-icon {
  color: #7c7c7c;
  font-size: 20px;
}
.altai-theme-admin .task-comment-create form {
  /* padding: 0 5px 0 5px; */
  font-size: 12px;
}
.altai-theme-admin .task-comment-create .spacer-vertical-200 {
  min-height: 0px;
  height: 0px;
}
.altai-theme-admin .task-comment-create .altai-admin-section table {
  margin: 5px 0 5px 0;
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 4px solid #eee;
  width: 100%;
  background-color: transparent;
}
.altai-theme-admin .task-comment-create .altai-file-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-top: 0px;
  /* padding-bottom: 20px; */
  width: 100%;
  background-color: #b5b5b5;
  flex-direction: column;
}
.altai-theme-admin .task-comment-create .altai-form .form-group,
.altai-theme-admin .task-comment-create .altai-theme-admin .altai-form .form-buttons,
.altai-theme-admin .task-comment-create .altai-theme-admin .altai-form h2 {
  flex-direction: column;
}
.altai-theme-admin .task-comment-create .altai-form .form-group label {
  flex: 0 0 25px;
}
.altai-theme-admin .task-comment-create .content-toolbar-container {
  width: 100%;
  margin-bottom: 0px;
}
.altai-theme-admin .task-comment-create .content-toolbar {
  top: 0;
  color: #fff;
  width: 100%;
  height: 45px;
  background-color: #ddd;
  box-shadow: none;
  position: relative;
  display: flex;
  top: 0px;
  z-index: 70;
  justify-content: space-between;
  border-bottom: 4px solid #d1d1d1;
}
.altai-theme-admin .task-comment-create .content-toolbar .altai-admin-icon,
.altai-theme-admin .task-comment-create .content-toolbar .icon {
  outline: none;
  border-radius: 50%;
  border: 2px solid transparent;
  height: 20px;
  width: 20px;
  background-color: transparent;
  color: #ffffff;
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0;
  display: inline-block;
}
.altai-theme-admin .task-comment-create .content-toolbar .altai-admin-icon:hover,
.altai-theme-admin .task-comment-create .content-toolbar .icon:hover {
  border: 2px solid #008cff;
  color: #008cff;
}
.altai-theme-admin .task-comment-create .content-toolbar a.md-button.md-default-theme.md-raised[disabled],
.altai-theme-admin .task-comment-create .content-toolbar a.md-button.md-raised[disabled],
.altai-theme-admin .task-comment-create .content-toolbar a.md-button.md-default-theme.md-fab[disabled],
.altai-theme-admin .task-comment-create .content-toolbar a.md-button.md-fab[disabled],
.altai-theme-admin .task-comment-create .content-toolbar .md-button.md-default-theme.md-raised[disabled],
.altai-theme-admin .task-comment-create .content-toolbar .md-button.md-raised[disabled],
.altai-theme-admin .task-comment-create .content-toolbar .md-button.md-default-theme.md-fab[disabled],
.altai-theme-admin .task-comment-create .content-toolbar .md-button.md-fab[disabled] {
  background-color: #585858;
  color: #b9b9b9 !important;
}
.altai-theme-admin .task-comment-create .toolbar-languages {
  padding-right: 15px;
}
.altai-theme-admin .task-comment-create .content-toolbar-items {
  color: #fff;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.altai-theme-admin .task-comment-create .content-toolbar-items ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  height: 45px;
}
.altai-theme-admin .task-comment-create .content-toolbar-items ul li {
  padding-left: 20px;
  padding-right: 20px;
  display: table-cell;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #004eff;
  color: #fff;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  vertical-align: middle;
  height: 45px;
}
.altai-theme-admin .task-comment-create .content-toolbar-items ul li i {
  color: #0068ff;
  padding-right: 5px;
}
.altai-theme-admin .task-comment-create .content-toolbar-items ul li.disabled {
  cursor: default;
  color: #aaa;
}
.altai-theme-admin .task-comment-create .content-toolbar-items ul li.disabled i {
  color: #aaa;
}
.altai-theme-admin .task-comment-create .content-toolbar-items ul li:hover {
  background-color: #F5F5F5;
  color: black;
}
.altai-theme-admin .task-comment-create .content-toolbar-items ul li.disabled:hover {
  background-color: #004eff;
  color: #aaa;
}
.altai-theme-admin .task-comment-create .content-toolbar-items .toolbar-items-vertical-center {
  padding-top: 2px;
}
.altai-theme-admin .task-comment-create .content-toolbar-items li.separator {
  cursor: default;
  padding-left: 0;
  padding-right: 0;
}
.altai-theme-admin .task-comment-create .content-toolbar-items li.separator:hover {
  background-color: #004eff;
}
.altai-theme-admin .task-comment-create .content-toolbar-items .separator-lines {
  width: 2px;
  box-shadow: #F9F9F9 -1px 0 inset;
  height: 45px;
  background-color: #c9c9c9;
}
.altai-theme-admin .task-comment-create .right {
  float: right;
}
.altai-theme-admin .task-comment-create .left {
  float: left;
}
.altai-theme-admin .task-comment-create .center {
  margin: auto;
}
.altai-theme-admin .fullwidthcard.historictask {
  padding: 5px;
  background-color: #f6f6f6;
}
